import React, { useRef, useState, useEffect } from 'react';
import Get from '../Fech/Get';
import { Link } from "react-router-dom";
import Header from '../components/Header/Header';
import Store from '../Fech/Store';
import Footer from '../components/Footer/Footer';
const TextPage = () => {
    const [text, setText] = useState('');
    const [loaded, SetLoaded] = useState('');
    const getText = async () => {



        var urlParams = new URLSearchParams(window.location.search);
        let page_id = urlParams.get('page_id');
        let data = await Get.post('get_text', {
            key: Get.key,
            hash: Get.userhash,
            page_id: page_id
            // tag: 'All'
        });

        return data;

    }

    useEffect(() => {
        const as = async () => {
            SetLoaded('loaded');
            const data = await getText();
            SetLoaded('');
            if (data.hasOwnProperty('text')) {
                setText(data.text);
            }

        }
        as();
    }, []);
    return (
        <>
            <Header></Header>

            <div className={"main"}>
                <div className={`container content ${loaded}`} style={{ minHeight: '300px' }} dangerouslySetInnerHTML={{ __html: text }}>

                </div>
                <Footer></Footer>
            </div>


        </>
    );
}
export default TextPage;