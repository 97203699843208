import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import './RegistrationContent.css';
import Get from '../../Fech/Get';

function RegistrationContent(props) {
    const minmax = {
        minValue: 0,
        maxValue: 100,
    };
    const location = useLocation();
    const [price, setPrice] = useState(25);
    const [nav, setNav] = useState(0);
    // const handleChangeNumber = (e) => {
    //     const numbers = e.target.value;
    //     setPrice(numbers);
    // };
    const [countres, setCountres] = useState([]);
    const handleChangeRange = (e) => {
        const numbers = e.target.value;
        setPrice(numbers);
    };
    const submitForm = async (e) => {
        e.preventDefault();

        e.target.classList.add("loaded");
        let formEntries = new FormData(e.target).entries();
        let data = Object.assign(...Array.from(formEntries, ([x, y]) => ({ [x]: y })));
        data.key = Get.key;
        let result = await Get.post('registration', data);
        e.target.classList.remove("loaded");
        if (result.hasOwnProperty('hash')) {
            let page = '/';
            let question = await Get.post('get_questions_list_start', data);
            if (Array.isArray(question)) {
                page = `/questionpage?id=${question[0].id}`;
            }
            localStorage.setItem("hash", result.hash);
            window.location.href = page;
        } else {
            alert('Registration failed');
        }
    }
    useEffect(() => {
        const getCountries = async () => {
            let result = await Get.post('get_country', {
                key: Get.key,
                hash: Get.userhash
            });
            setCountres(result);
        };
        getCountries();
    }, []);
    return (


        <>
            <div className={"RegistrationContent"}>
                <form onSubmit={(e) => { submitForm(e) }}>
                    <div className={"RegistrationContentTitle"}>A little about you</div>
                    <div className="container">
                        <div className={"RegistrationContentGender"}>Gender</div>

                        <div className={"RegistrationContentSelect"}>
                            <select name="gender">
                                <option value='Men'>Men</option>
                                <option value='Woman'>Woman</option>
                                <option value='Other'>Other</option>
                            </select>
                        </div>

                        <div className={"RegistrationContentGender"}>Country living</div>

                        <div className={"RegistrationContentSelect"}>
                            <select name='country'>
                                {countres.map((el, index) => {
                                    return <option key={index} value={el.name}>{el.name}</option>
                                })}
                            </select>
                        </div>

                        <div className={"RegistrationContentAge"}>Age</div>
                        <div className={"RegistrationContentAgeRange"}>
                            <div className={"RegistrationContentAgePrice"}>
                                {price}
                            </div>

                            <input
                                type="range"
                                min={minmax.minValue}
                                max={minmax.maxValue}
                                name="age"
                                onChange={
                                    (e) => handleChangeRange(e)
                                }
                                value={price}
                            />
                        </div>

                        <button type='submit' className={"RegistrationContentLetsgo"}>let’s go!</button>
                    </div>
                    <div className={"RegistrationContentFooter"}>
                        {/*<img src={require('../../images/Logo1.svg').default} alt=""/>*/}
                    </div>
                </form>
            </div>
        </>

    )
}

export default RegistrationContent;