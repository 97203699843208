import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Get from '../../Fech/Get';
const QuestionForm = (props) => {
    const [question, setQuestion] = useState(props.question);
    console.log(question);
    const setFavorite = async (int) => {
        let action = int == 0 ? 'UnsetFavorites' : 'setFavorites';
        let data = await Get.post('get_favorites', {
            key: Get.key,
            hash: Get.userhash,
            action: action,
            id: question.id

        });

        setQuestion({ ...question, favorites: int });

    }
    return (
        <form onSubmit={(e) => { props.submitForm(e) }}>
            <div className={"main pageQuestion"}>
                <div className={"mainQuestion container"}>{question.question_name}</div>

                <div className={"votesTimeFavorite"}>
                    <div>
                        <img src={require('../../images/question.svg').default} alt="" />
                        <pre>{question.respondents} votes</pre>
                    </div>
                    <div>
                        <img src={require('../../images/time.svg').default} alt="" />
                        yesterday
                    </div>
                    <div>

                        {question.favorites == 1 ?
                            <span className='favspan' onClick={() => { setFavorite(0) }}><img src={require('../../images/favorite.svg').default} alt="" />in favorites</span> :
                            <span className='favspan' onClick={() => { setFavorite(1) }}><img src={require('../../images/infavorite.svg').default} alt="" />to favorites</span>}

                    </div>
                </div>

                <hr />

                <div className={"checkBox"}>
                    <div className={"container"}>
                        {question.answers.map((el, index) => {
                            return <div key={index}>
                                <input name='ansfer' id={`ansfer${index}`} defaultChecked={index == 0 ? true : false} type="radio" value={el.id} />
                                <label htmlFor={`ansfer${index}`}>{el.answer}</label>
                            </div>
                        })}
                    </div>
                </div>

                <div className={`marginTop`}>


                    <div className={"inputButton"} >
                        <Link to="/">
                            <img src={require('../../images/Frame.svg').default} alt="" />
                        </Link>
                        <input type="submit" value={'submit'} />
                    </div>


                    <div className={"enjoy"}>
                        <div
                            className={
                                "footerImgBackground firstQuestionResultContentFooter3 firstQuestionResultContentFooter5"
                            }>
                        </div>
                        <div className={"enjoyBlock1"}>enjoy to similar polls</div>
                        <div className={"hashtagsBlock"}>

                            {Array.isArray(question.tags) ?

                                question.tags.map((el, index) => {
                                    return <Link to={`/listqvestion?tag=${el}`} key={index}><span>#</span>{el}</Link>
                                }) : ''}

                        </div>
                        <Link to="/subscribedUserQuestion" className="enjoyBlock2">or create your own</Link>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default QuestionForm;