import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import './QuestionsListTabs.css';
import Get from '../../Fech/Get';
import { Link } from "react-router-dom";

export const BasicTabs = (props) => {

    const [activeQvest, setActiveQvest] = useState([]);
    const [query, setQuery] = useState('get_questions_list_new');
    const [link, setLinks] = useState([
        { title: 'new polls', query: 'get_questions_list_new', active: 'active' },
        { title: 'hot polls', query: 'get_questions_list_hot', active: '' },
        { title: 'top polls', query: 'get_questions_list_top', active: '' },
        { title: 'voted polls', query: 'get_questions_list_voted', active: '' },
        { title: 'my own polls', query: 'get_questions_list_myown', active: '' },
        { title: 'favorites', query: 'get_favorites' },
    ]);
    const [loaded, SetLoaded] = useState('');
    const setActiveTabs = (index) => {
        setLinks(link.map((tag, i) => {
            return {
                title: tag.title,
                query: tag.query,
                active: i === index ? 'active' : ''
            }
        }));
        setQuery(link[index].query);
    }
    useEffect(() => {
        const as = async () => {
            SetLoaded('loaded');
            let data = await Get.post(query, {
                key: Get.key,
                hash: Get.userhash,
                // tag: 'All'
            });
            setActiveQvest(data);
            SetLoaded('');
        }
        as();
    }, [query]);
    return (
        <div className='tabs'>
            <div className='scroll-tags'>
                <div className='tag-container'>
                    {link.map((tag, index) => {

                        return <div key={index} onClick={() => { setActiveTabs(index) }} className={`scroll-tag ${tag.active}`} >{tag.title}</div>

                    })}
                </div>
            </div>
            <div className={`scroll-questions container ${loaded}`}>
                {activeQvest.map((question, index) => {
                    return <Link to={`/questionpage?id=${question.id}`} key={index} className='question-item'>

                        {question.is_favorite == 1 ? <div className='add-to-favorites'></div> : ''}
                        <div className='question-title'>{question.name}</div>
                        <div className='question-options'>
                            <div className='question-votes'>{question.respondents} votes</div>
                            <div className='question-data'>{Get.dateFormatTimestapTime(question.date)}</div>
                        </div>
                    </Link>
                })}
            </div>
        </div>
    );
}
export default BasicTabs;