import React, { useState, useEffect } from 'react';
import './footer.css';
import Store from '../../Fech/Store';
import Get from '../../Fech/Get';
import { Link } from "react-router-dom";
function Footer(props) {
    const [tags, setTags] = useState([]);
    const [loaded, SetLoaded] = useState('');
    const getTags = async () => {

        if (Store.TopQuestion.length == 0) {
            Store.TopQuestion = await Get.post('get_questions_list_top', {
                key: Get.key,
                hash: Get.userhash,
                // tag: 'All'
            });

        }
        return Store.TopQuestion;
    }
    useEffect(() => {
        const as = async () => {
            SetLoaded('loaded');
            let tagsresp = await getTags();

            let tags = [];
            if (Array.isArray(tagsresp)) {
                tags = tagsresp[0].tags;
                setTags(tags);
            }
            SetLoaded('');
        }
        as();
    }, []);

    return (


        <div className={`enjoy ${loaded}`}>
            <div
                className={
                    "footerImgBackground firstQuestionResultContentFooter3 firstQuestionResultContentFooter5"
                }>
            </div>
            <div className={"enjoyBlock1"}>enjoy to similar polls</div>
            <div className={"hashtagsBlock"}>
                {Array.isArray(tags) ?

                    tags.map((el, index) => {
                        return <Link to={`/listqvestion?tag=${el}`} key={index}><span>#</span>{el}</Link>
                    }) : ''}
            </div>
            <Link to="/subscribedUserQuestion" className="enjoyBlock2">or create your own</Link>
        </div>



    );
}

export default Footer;